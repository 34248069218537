import React from "react";
import Chero from "../components/chero";
import Head from "../components/head";
import Cl1 from "../components/pages/clients/cl1/cl1";
import Cl3 from "../components/pages/clients/cl3/cl3";
import Cl4 from "../components/pages/clients/cl4/cl4";
const Clients = () => (
  <>
    <Head
      title="Clients"
      dis="Premise Healthcare are a reliable and fast growing secondary supplier to some of the largest frameworks in the UK and we do not intend to stop there"
    />
    <Chero herotitle="Clients" />
    <Cl1 />
    <Cl3 />
    <Cl4 />
  </>
);

export default Clients;
