import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./cl4.scss";
export default () => {
  return (
    <div className="cl4">
      <div className="cl4-container container">
        <div className="cl4img1-container">
          <div className="cl4img1"></div>
          <div className="cl4img2"></div>
        </div>
        <div className="cl4div1">
          <h1>Compliance</h1>
          <p>
            We have streamlined the process and given you total transparency and
            complete control for you to complete everything online through an
            online profile, at a time convenient to you. <br />
            <br />
            <em>The Compliance Process</em>
            <br />
            <br />
            Before you can work with us, you need to complete full registration
            and become fully compliant. There are multiple requirements that
            contribute towards this, including: <br />
            <br />
            <ul>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p> Application Form</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>Serology Reports</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>BLS/PLS</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>DBS</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>Mandatory Training</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>References</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>Proofs of Address</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>Medical Questionnaire</p>
              </li>
              <li>
                <span>
                  <FaCheckCircle />
                </span>
                <p>Indemnity Insurance</p>
              </li>
            </ul>
            <br />
            Don’t worry though, we’re here to help you at every stage. Your
            recruitment consultant will guide you through the process and advise
            what’s due and by when. You can also see for yourself through your
            Premise Healthcareonline profile, which allows you to see your exact
            status, what you need to provide, and what’s due to expire – and be
            able to provide it all at the click of a button. <br />
            <br />
            This unrivalled level of transparency makes it easier than ever
            before to keep on top of your compliance so we can get you out to
            work sooner.
          </p>
        </div>
      </div>
    </div>
  );
};
